import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_divider_testimonials()
})

function dhsv_vc_divider_testimonials() {
  $('.dhsv_vc_divider_testimonials').each(function() {
    $('.dhsv_vc_divider_slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<i class="slick-prev ion-chevron-left"></i>',
      nextArrow: '<i class="slick-next ion-chevron-right"></i>',
      dots: true,
    })
  })
}
