import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_stage()
})

function dhsv_vc_stage() {
  $('.dhsv_vc_stage').each(function() {
    // big single slider
    $('.dhsv_vc_stage_slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true,
      asNavFor: '.dhsv_vc_stage_nav_slider',
    })
    // little navigation slider
    $('.dhsv_vc_stage_nav_slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      asNavFor: '.dhsv_vc_stage_slider',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow:
              '<i class="slick-arrow slick-prev ion-ios-arrow-thin-left"></i>',
            nextArrow:
              '<i class="slick-arrow slick-next ion-ios-arrow-thin-right"></i>',
          },
        },
      ],
    })
    // on click on navigation go to slide
    $('.dhsv_vc_stage_item_nav').click(function(e) {
      e.preventDefault()
      var slideindex = $(this).attr('data-slideindex')
      $('.dhsv_vc_stage_slider').slick('slickGoTo', slideindex)
    })
  })
}
