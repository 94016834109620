export default {
  init() {
    // JavaScript to be fired on all pages
    $(document).ready(function() {
      init_sub_menu()
      init_searchform()
      init_mobilemenu()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function init_sub_menu() {
  // main menu first level click
  $('.nav-primary ul.nav > .menu-item-has-children > a').on(
    'click tap',
    function() {
      $('.sub-menu').removeClass('open-sub-menu')
      $('.nav-primary ul.nav > .menu-item-has-children a').removeClass(
        'opened-sub-menu'
      )
      $('.submenubanner').removeClass('show-submenubanner')
      $('.submenubanner').removeClass('show-submenubanner-twocol')
      $(this)
        .addClass('opened-sub-menu')
        .next('.sub-menu')
        .addClass('open-sub-menu')
      $('.submenubanner').addClass('show-submenubanner')
    }
  )

  // $('.nav-primary ul.nav > .menu-item-has-children').on(
  //   'mouseleave',
  //   function() {
  //     $('.sub-menu').removeClass('open-sub-menu')
  //     $('.submenubanner').removeClass('show-submenubanner')
  //     $('.submenubanner').removeClass('show-submenubanner-twocol')
  //   }
  // )

  // fade content and footer on click
  $('.nav-primary ul.nav').on('click tap', function() {
    $('.wrap').addClass('fade-wrap')
    $('footer.content-info').addClass('fade-footer')
  })

  // $('.nav-primary ul.nav > li').on('mouseleave', function() {
  //   $('.wrap').removeClass('fade-wrap')
  //   $('footer.content-info').removeClass('fade-footer')
  // })

  // sub menu level click
  $(
    '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children > a'
  ).on('click tap', function() {
    $(
      '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu'
    ).removeClass('open-sub-menu')
    $(
      '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children'
    ).removeClass('open-sub-menu')
    $(this)
      .next('.sub-menu')
      .addClass('open-sub-menu')
      .parent()
      .addClass('open-sub-menu')
    $('.submenubanner').addClass('show-submenubanner-twocol')
  })

  // $(
  //   '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children'
  // ).on('mouseleave', function() {
  //   $(this)
  //     .children('.sub-menu')
  //     .removeClass('open-sub-menu')
  //     .parent()
  //     .removeClass('open-sub-menu')
  //   $('.submenubanner').removeClass('show-submenubanner-twocol')
  // })

  // on scroll remove all menu classes
  $(window).scroll(function() {
    $('.sub-menu').removeClass('open-sub-menu')
    $('.nav-primary ul.nav > .menu-item-has-children a').removeClass(
      'opened-sub-menu'
    )
    $(
      '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children'
    ).removeClass('open-sub-menu')
    $('.submenubanner').removeClass('show-submenubanner')
    $('.submenubanner').removeClass('show-submenubanner-twocol')
    $('.wrap').removeClass('fade-wrap')
    $('footer.content-info').removeClass('fade-footer')
  })

  // remove all menu classes on click outside
  $('.wrap').on('click tap', function() {
    $('.sub-menu').removeClass('open-sub-menu')
    $('.nav-primary ul.nav > .menu-item-has-children a').removeClass(
      'opened-sub-menu'
    )
    $(
      '.nav-primary ul.nav > .menu-item-has-children > .sub-menu > .menu-item-has-children'
    ).removeClass('open-sub-menu')
    $('.submenubanner').removeClass('show-submenubanner')
    $('.submenubanner').removeClass('show-submenubanner-twocol')
    $(this).removeClass('fade-wrap')
    $('footer.content-info').removeClass('fade-footer')
  })
}

function init_searchform() {
  var lang = $('html')[0].lang
  var winwidth = $(window).width()
  var placeholder_de = ''
  var placeholder_en = ''
  if (winwidth > 768) {
    placeholder_de =
      'Können wir Ihnen bei der Suche nach dem passenden Kurs helfen?'
    placeholder_en = 'Can we help you find the right course?'
  } else {
    placeholder_de = 'Können wir Ihnen helfen?'
    placeholder_en = 'Can we help you?'
  }
  if (lang == 'de-DE') {
    $(this).val(placeholder_de)
  }
  if (lang == 'en-EN') {
    $(this).val(placeholder_en)
  }
  $('.search-field')
    .focus(function() {
      if ($(this).val() == placeholder_de || $(this).val() == placeholder_en) {
        $(this).val('')
      }
    })
    .blur(function() {
      if ($(this).val() == '') {
        if (lang == 'de-DE') {
          $(this).val(placeholder_de)
        }
        if (lang == 'en-EN') {
          $(this).val(placeholder_en)
        }
      }
    })

  $('.topbanner-search').on('click tap', function(e) {
    e.preventDefault()
    $(this)
      .find('i')
      .toggleClass('ion-ios-search-strong')
      .toggleClass('ion-close')
    $('.headersearchform').toggleClass('openheadersearchform')
  })
}

function init_mobilemenu() {
  $('#togglemobilemenu').on('click tap', function(e) {
    e.preventDefault()
    $('body').toggleClass('overflowhidden')
    $(this)
      .find('i')
      .toggleClass('ion-navicon')
      .toggleClass('ion-close')
    $('#mobilemenu').toggleClass('openmobilemenu')
  })

  $('#mobilemenu ul.nav .menu-item-has-children > a').on('click tap', function(
    e
  ) {
    e.preventDefault()
    $(this)
      .parent()
      .children('.sub-menu')
      .toggleClass('open-sub-menu')
  })
}
