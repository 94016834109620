import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_customerlist()
})

function dhsv_vc_customerlist() {
  $('.dhsv_vc_customerlist').each(function() {
    var counter = 0
    $(this)
      .find('li')
      .each(function() {
        counter++
        if (counter > 5) {
          $(this).addClass('showonexpand')
          $(this).addClass('hiddencustomer')
        }
      })
  })

  $('#showmorecustomer').on('click tap', function(e) {
    e.preventDefault()
    if ($(this).text() == 'mehr anzeigen') {
      $(this).text('weniger anzeigen')
    } else {
      $(this).text('mehr anzeigen')
    }
    $(this)
      .parent()
      .find('li')
      .each(function() {
        if ($(this).hasClass('showonexpand')) {
          $(this).toggleClass('hiddencustomer')
        }
      })
  })
}
