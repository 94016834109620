import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_expand_text()
})

function dhsv_vc_expand_text() {
  $('.dhsv_vc_expand_text').each(function() {
    $('.dhsv_vc_expand_text_icon').click(function() {
      $(this)
        .parent()
        .parent()
        .addClass('dhsv_vc_expand_text_full')
    })
    $('.dhsv_vc_expand_text_icon_close').click(function() {
      $(this)
        .parent()
        .parent()
        .removeClass('dhsv_vc_expand_text_full')
    })
  })
}
